var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "유해요인 목록",
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          gridHeight: _vm.grid.height,
          filtering: false,
          columnSetting: false,
          selection: _vm.popupParam.type,
          rowKey: "sopRiskFactorItemId",
        },
        on: { rowDblclick: _vm.rowDblclick },
      }),
      _c("div", { staticClass: "space-bottom-button" }),
      _c(
        "div",
        { staticClass: "search-box-btn" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _c("c-btn", {
                attrs: { label: "선택", icon: "check", color: "teal-5" },
                on: { btnClicked: _vm.select },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }