<template>
  <div>
    <c-table
      ref="table"
      title="유해요인 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :filtering="false"
      :columnSetting="false"
      :selection="popupParam.type"
      rowKey="sopRiskFactorItemId"
      @rowDblclick="rowDblclick"
    >
    </c-table>
    <div class="space-bottom-button"></div>
    <!-- 버튼 영역 -->
    <div class="search-box-btn">
      <q-btn-group outline >
        <c-btn label="선택" icon="check" color="teal-5" @btnClicked="select" />
      </q-btn-group>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskFactorItemPop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        columns: [
          
        {
            name: 'tbmRisk',
            field: 'tbmRisk',
            label: '위험요인',
            align: 'left',
            sortable: true,
            style: 'width:40%',
          },
          {
            name: 'beforeRiskName',
            field: 'beforeRiskName',
            label: '개선 전<br/>판단결과',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
          {
            name: 'tbmImprove',
            field: 'tbmImprove',
            label: '안전대책',
            align: 'left',
            sortable: true,
            style: 'width:40%',
          },
          {
            name: 'afterRiskName',
            field: 'afterRiskName',
            label: '개선 후<br/>판단결과',
            align: 'center',
            style: 'width:10%',
            type: 'html',
            sortable: false,
            comboItems: this.riskStandardItems,
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.rft.riskFactorItem.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
